<template>
  <v-container class="image-download">
    <div
      v-if="$vuetify.breakpoint.xs"
      class="back-buton-mobile"
      @click="$router.go(-1)"
    >
      <v-icon color="link" small>mdi-arrow-up-left</v-icon>
      {{ $t("images.back") }}
    </div>
    <h1 class="primary--text">{{ $t("images.title") }}</h1>
    <div
      @click="$router.go(-1)"
      class="subTitle"
      v-if="!$vuetify.breakpoint.xs"
    >
      <v-icon small color="link">mdi-arrow-left</v-icon>
      <a class="link--text"> {{ $t("images.back") }}</a>
    </div>
    <div>
      <v-stepper v-model="e1" flat>
        <v-stepper-header>
          <v-stepper-step :complete="e1 > 1" step="1">
            <span>{{ $t("images.firstStep.title") }}</span>
          </v-stepper-step>

          <!-- <v-divider v-if="e1 === 1"></v-divider> -->

          <v-stepper-step
            :complete="e1 > 2"
            step="2"
            :class="{ disabled: noImages }"
          >
            <span>{{ $t("images.secondStep.title") }}</span>
          </v-stepper-step>

          <!-- <v-divider v-if="e1 === 2"></v-divider> -->

          <v-stepper-step
            :complete="e1 > 3"
            step="3"
            :class="{ disabled: noImages }"
          >
            <span>{{ $t("images.thirdStep.title") }}</span>
          </v-stepper-step>

          <!-- <v-divider v-if="e1 === 3"></v-divider> -->

          <v-stepper-step step="4">
            <span>{{ $t("images.fourthStep.title") }}</span>
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <ImageFormat
              v-if="product"
              :cart="cart"
              @formatSelected="formatSelected"
              :skipImages="skipImages"
              :product="product"
            />
          </v-stepper-content>

          <v-stepper-content step="2">
            <TypeOfShots
              :imageOptions="imageOptions"
              :cart="cart"
              :availableTypesForUser="availableTypesForUser"
              @typeSelected="typeSelected"
              @previous="previous"
            />
          </v-stepper-content>

          <v-stepper-content step="3">
            <NameFile
              v-if="product"
              :imageOptions="imageOptions"
              :cart="cart"
              :product="product"
              :trigerName="trigerName"
              @nameSelected="nameSelected"
              @previous="previous"
              @toStart="toStart"
            />
          </v-stepper-content>
          <v-stepper-content step="4">
            <DownloadImage
              :imageOptions="imageOptions"
              :cart="cart"
              @emailSelected="emailSelected"
              @previous="previous"
              @toStart="toStart"
            />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>
  </v-container>
</template>

<script>
import ImageFormat from "./ImageFormat.vue";
import TypeOfShots from "./TypeOfShot.vue";
import NameFile from "./NameFile.vue";
import DownloadImage from "./DownloadImage.vue";

import ProductService from "~/service/productService";
import ExportDataService from "~/service/expordDataService";
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  name: "GetImages",
  components: {
    ImageFormat,
    TypeOfShots,
    NameFile,
    DownloadImage
  },
  data() {
    return {
      product: null,
      slug: null,
      isCart: false,
      trigerName: false,
      e1: 1,
      imageOptions: {
        format: null,
        type: [],
        name: null,
        mail: null
      },
      noImages: false,
      availableTypesForUser: [],
      skipImages: true
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    ...mapGetters({
      cartId: "cart/getCartId"
    })
  },
  methods: {
    ...mapActions({
      loadCart: "cart/getCart"
    }),
    formatSelected(format, images) {
      this.noImages = !images;
      this.e1 = images ? 2 : 4;
      this.imageOptions.format = format;
    },
    typeSelected(type) {
      this.imageOptions.type = type;
      this.e1 = 3;
      this.trigerName = !this.trigerName;
    },
    nameSelected(name) {
      this.e1 = 4;
      this.imageOptions.name = name;
    },
    emailSelected(email) {
      this.imageOptions.mail = email;
      console.log("Selected options", this.imageOptions);
      this.$router.go(-1);
    },
    previous() {
      if (this.e1 > 1) this.e1--;
    },
    toStart() {
      this.e1 = 1;
    },
    async getProduct() {
      // await this.loadCart();
      console.log("GET PRODUCT SLUG", this.slug);
      var data = await ProductService.getProductBySlug(this.slug);
      if (data) {
        this.product = data;
      }
      console.log("GET PRODUCT", this.product);
    },
    async getAvalableShots() {
      let data = await ExportDataService.getAvailableImageTypeShots(
        this.cartId
      );

      this.availableTypesForUser = data.photo_format;
      this.skipImages = data.skip_image == "true" ? true : false;
    }
  },
  async mounted() {
    if (this.$route.params.slug) {
      // this.slug = this.$route.params.slug;
      // await this.getProduct();
      this.isCart = this.$route.query.cart;
      this.slug = this.cart.cartItems[0].product.slug;
      await this.getProduct();
    } else if (this.$route.query.cart) {
      this.isCart = this.$route.query.cart;
      this.slug = this.cart.cartItems[0].product.slug;
      await this.getProduct();
    }
    await this.getAvalableShots();
  },
  watch: {
    async $route(to) {
      if (this.$route.params.slug && to.params.slug != this.slug) {
        // this.slug = to.params.slug;
        // await this.getProduct();
        this.isCart = this.$route.query.cart;
        this.slug = this.cart.cartItems[0].product.slug;
        await this.getProduct();
      } else if (this.$route.params.cart) {
        this.isCart = this.$route.query.cart;
        this.slug = this.cart.cartItems[0].product.slug;
        await this.getProduct();
      }
      await this.getAvalableShots();
    }
  },
  metaInfo() {
    return {
      title: this.$t("images.title")
    };
  }
};
</script>
<style scoped lang="scss">
.back-buton-mobile {
  margin-top: 0px !important;
}
</style>
