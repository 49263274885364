<template
  ><v-card class="image-personalize">
    <v-toolbar dark color="primary" elevation="0" dense>
      <v-toolbar-title>{{
        $t("images.personalizeImage.title")
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-card-text>
      <v-form v-model="isFormValid">
        <v-row class="mt-3">
          <v-col cols="12" md="4">
            <h3 class="primary--text">
              {{ $t("images.personalizeImage.fileType") }}
            </h3>
            <v-select
              v-model="imageSettings.type"
              dense
              solo
              flat
              :items="types"
              :label="$t('common.select')"
              outlined
              :rules="rules"
            ></v-select>
            <h3 class="primary--text">
              {{ $t("common.dpi") }}
            </h3>
            <v-select
              v-model="imageSettings.dpi"
              dense
              solo
              flat
              color="primary"
              :items="dpis"
              item-text="label"
              item-value="value"
              :label="$t('common.select')"
              outlined
              :rules="rules"
            ></v-select>
            <h3 class="primary--text">
              {{ $t("images.personalizeImage.spacing") }}
            </h3>
            <v-row no-gutters>
              <v-col cols="10" md="9">
                <v-text-field
                  solo
                  flat
                  outlined
                  dense
                  type="number"
                  v-model.number="spacing.spacingValue"
                />
              </v-col>
              <v-col cols="2" md="3">
                <v-select
                  dense
                  solo
                  flat
                  :items="units"
                  :label="$t('images.personalizeImage.unit')"
                  outlined
                  v-model="spacing.spacingType"
                ></v-select>
              </v-col>
            </v-row>
            <h3 class="primary--text">
              {{ $t("images.personalizeImage.pixelFormat") }}
            </h3>
            <v-select
              v-model="imageSettings.pixelFormat"
              dense
              solo
              flat
              :items="formats"
              :label="$t('common.select')"
              outlined
            ></v-select>
            <h3 class="primary--text">
              {{ $t("images.personalizeImage.moreOptions") }}
            </h3>
            <v-checkbox
              :label="$t('images.personalizeImage.includeMetadata')"
              v-model="imageSettings.metadata"
            ></v-checkbox>
          </v-col>
          <v-col cols="12" md="4">
            <h3 class="primary--text">
              {{ $t("images.personalizeImage.dimensions") }}
            </h3>
            <v-row no-gutters>
              <v-col cols="5" md="4">
                <v-text-field
                  :label="$t('images.personalizeImage.length')"
                  solo
                  flat
                  outlined
                  type="number"
                  dense
                  v-model="dimension.dimensionLength"
                  :rules="rules"
                />
              </v-col>
              <v-col cols="5" md="4">
                <v-text-field
                  :label="$t('images.personalizeImage.height')"
                  solo
                  flat
                  outlined
                  type="number"
                  dense
                  v-model="dimension.dimensionHeight"
                  :rules="rules"
                />
              </v-col>
              <v-col cols="2" md="4">
                <v-select
                  v-model="dimension.dimensionType"
                  dense
                  solo
                  flat
                  :items="units"
                  :label="$t('images.personalizeImage.unit')"
                  outlined
                  :rules="rules"
                ></v-select>
              </v-col>
            </v-row>
            <h3 class="primary--text">
              {{ $t("images.personalizeImage.background") }}
            </h3>
            <v-radio-group v-model="background" row>
              <v-radio
                :label="$t('images.personalizeImage.transparent')"
                value="transparent"
                @click="backgroundColor = '#FFFFFF00'"
              ></v-radio>
              <v-radio
                :label="$t('images.personalizeImage.color')"
                value="color"
                @click="backgroundColor = '#FFFFFFFF'"
              ></v-radio>
            </v-radio-group>
            <v-color-picker
              v-model="backgroundColor"
              dot-size="25"
              :swatches="swatches"
              show-swatches
              :disabled="colorPickerDisabled"
            ></v-color-picker>
          </v-col>
          <v-col cols="12" md="4" class="pa-0">
            <div class="img-container" v-bind:style="style">
              <img :src="imageUrl" alt="Immagine prodotto " />
            </div>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn color="primary" tile @click="close" class="text-capitalize">
        {{ $t("common.cancel")
        }}<v-icon right>
          mdi-close
        </v-icon>
      </v-btn>

      <v-btn
        color="primary"
        tile
        @click="save"
        class="text-capitalize"
        :disabled="!isFormValid"
      >
        {{ $t("common.save")
        }}<v-icon right>
          mdi-content-save-outline
        </v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "PersonalizeImage",
  props: { imageUrl: { required: true, type: String } },
  computed: {
    colorPickerDisabled() {
      return this.background === "transparent" ? true : false;
    },
    style() {
      return {
        padding: `${this.spacing.spacingValue}${this.spacing.spacingType} !important`,
        backgroundColor: this.backgroundColor
      };
    }
  },
  data() {
    return {
      spacing: { spacingValue: null },
      dimension: { dimensionsValue: null },
      imageSettings: { background: "#FFFFFF00", metadata: false },
      background: "transparent",
      backgroundColor: "#FFFFFF00",
      types: ["TIFF", "JPG", "PNG"],
      dpis: [
        { label: "300 (stampa)", value: "300" },
        { label: "72 (web)", value: "72" }
      ],
      units: ["px", "mm", "cm", "inc"],
      formats: ["Format 1", "Format 2"],
      swatches: [
        ["#FF0000"],
        ["#FFFF00"],
        ["#00FF00"],
        ["#00FFFF"],
        ["#0000FF"]
      ],
      rules: [value => !!value || this.$t("images.fourthStep.required")],
      isFormValid: false
    };
  },
  methods: {
    ...mapActions({
      setCartInfo: "cart/setCartInfo"
    }),
    save() {
      if (this.spacing.spacingValue && this.spacing.spacingType) {
        this.imageSettings.spacing =
          "" + this.spacing.spacingValue + " " + this.spacing.spacingType;
      }
      this.imageSettings.resolution =
        "" +
        this.dimension.dimensionLength +
        "x" +
        this.dimension.dimensionHeight +
        " " +
        this.dimension.dimensionType;
      if (this.background === "transparent") {
        this.imageSettings.background = "";
      } else this.imageSettings.background = this.backgroundColor;
      console.log(this.background);
      this.$emit("save", this.imageSettings);
      // this.setCartInfo(this.imageSettings);
    },
    close() {
      this.$emit("closeDialog");
    }
  }
};
</script>
<style scoped>
.img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}
</style>
