<template>
  <v-container>
    <p>{{ $t("images.thirdStep.subTitle") }}</p>
    <div>
      <p class="pt-6">{{ $t("images.thirdStep.selectedName") }}</p>
      <div class="w-100 chip-container pa-2 my-2">
        <v-chip
          v-for="(name, index) in selected"
          :key="index"
          close
          close-icon="mdi-close"
          color="secondary"
          label
          class="px-3 ma-1"
          @click:close="removeName(name)"
        >
          {{ name.label }}
        </v-chip>
      </div>
      <span class="font-weight-bold"
        >{{ $t("images.thirdStep.preview") }}: </span
      >{{ name }}
      <p class="pt-6">{{ $t("images.thirdStep.availableNameOptions") }}</p>
      <div class="d-flex justify-start flex-wrap">
        <v-chip
          v-for="(name, index) in available"
          :key="index"
          close
          close-icon="mdi-plus"
          color="primary"
          label
          outlined
          class="px-3 ma-1"
          @click="addName(name)"
          @click:close="addName(name)"
        >
          {{ name.label }}
        </v-chip>
      </div>
    </div>
    <div class="d-flex justify-end pt-6">
      <v-btn color="primary" outlined @click="previous" class="mr-3">
        <v-icon left>
          mdi-arrow-left
        </v-icon>
        {{ $t("images.secondStep.title") }}
      </v-btn>
      <v-btn color="primary" @click="next" :disabled="buttonDisabled">
        {{ $t("images.fourthStep.title") }}
        <v-icon right>
          mdi-arrow-right
        </v-icon>
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "NameFile",
  props: {
    imageOptions: { required: true, type: Object },
    product: { required: true, type: Object },
    trigerName: { required: true, type: Boolean },
    cart: { type: Object }
  },
  computed: {
    available() {
      return this.availableNames.filter(name => {
        if (name.value === false) {
          return name;
        }
      });
    },
    buttonDisabled() {
      if (
        this.selected.length < 1 ||
        !(
          this.containsName("ShotTypeId") ||
          this.containsName("ShotTypeId (number only)")
        )
      ) {
        return true;
      }
      return false;
    }
  },
  data() {
    return {
      id: 1,
      selected: [],
      name: "",
      nameForm: "",
      availableNames: [
        { label: "Codice prodotto", value: false },
        { label: "Marcatura oraria generata", value: false },
        { label: "Ampiezza pixel", value: false },
        { label: "Altezza pixel", value: false },
        { label: "DPI", value: false },
        { label: "ShotTypeId", value: false },
        { label: "ShotTypeId (number only)", value: false },
        { label: "_", value: false }
      ]
    };
  },
  methods: {
    ...mapActions({
      setCartInfo: "cart/setCartInfo"
    }),
    addName(chip) {
      if (chip.label != "_") {
        this.availableNames[
          this.availableNames.findIndex(name => name.label === chip.label)
        ].value = true;
      } else {
        chip.id = this.id++;
      }
      this.selected.push({ ...chip });
      this.createNameString();
    },
    createNameString() {
      this.name = "";
      this.nameForm = "";
      this.selected.forEach(element => {
        var resolution = this.imageOptions.format?.images.resolution.split("x");
        switch (element.label) {
          case "_":
            this.name = this.name.concat("", "_");
            this.nameForm = this.nameForm.concat("", "_");
            break;
          case "Codice prodotto":
            this.name = this.name.concat(
              "",
              this.product.barcode + "_" + this.product.codeVariant
            );
            this.nameForm = this.nameForm.concat("", "ProductCode");
            break;
          case "ShotTypeId":
            this.name = this.name.concat("T", this.imageOptions.type[0]);
            this.nameForm = this.nameForm.concat("", "ShotTypeId");
            break;
          case "ShotTypeId (number only)":
            this.name = this.name.concat("", this.imageOptions.type[0]);
            this.nameForm = this.nameForm.concat(
              "",
              "ShotTypeIdWithNumberOnly"
            );
            break;
          case "DPI":
            this.name = this.name.concat(
              "",
              this.imageOptions.format.images.dpi
            );
            this.nameForm = this.nameForm.concat("", "DPI");
            break;
          case "Ampiezza pixel":
            this.name = this.name.concat("", resolution[0]);
            this.nameForm = this.nameForm.concat("", "PixelWidth");
            break;
          case "Altezza pixel":
            this.name = this.name.concat("", resolution[1]);
            this.nameForm = this.nameForm.concat("", "PixelHeight");
            break;
          case "Marcatura oraria generata":
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, "0");
            var mm = String(today.getMonth() + 1).padStart(2, "0");
            var yyyy = today.getFullYear();
            var hh = today.getHours();
            var min = today.getMinutes();
            var ss = today.getSeconds();
            today =
              dd + "-" + mm + "-" + yyyy + "--" + hh + "-" + min + "-" + ss;
            this.name = this.name.concat("", today);
            this.nameForm = this.nameForm.concat("", "TimeStamp");
            break;
        }
        this.nameForm = this.nameForm + "/";
      });
      this.nameForm = this.nameForm.slice(0, -1);
      this.selected.length < 1
        ? (this.name = "")
        : (this.name = this.name.concat("", ".tiff"));
    },
    recreate(items) {
      items.forEach(element => {
        switch (element) {
          case "_":
            this.addName({ label: element, value: false });
            break;
          case "ProductCode":
            this.addName({ label: "Codice prodotto", value: false });
            break;
          case "ShotTypeId":
            this.addName({ label: "ShotTypeId", value: false });
            break;
          case "ShotTypeIdWithNumberOnly":
            this.addName({ label: "ShotTypeId (number only)", value: false });
            break;
          case "DPI":
            this.addName({ label: "DPI", value: false });
            break;
          case "PixelWidth":
            this.addName({ label: "Ampiezza pixel", value: false });
            break;
          case "PixelHeight":
            this.addName({ label: "Altezza pixel", value: false });
            break;
          case "TimeStamp":
            this.addName({ label: "Marcatura oraria generata", value: false });
            break;
        }
      });
    },
    createDefault() {
      this.addName({ label: "Codice prodotto", value: false });
      this.addName({ label: "_", value: false });
      this.addName({ label: "ShotTypeId", value: false });
    },
    removeName(chip) {
      if (chip.label.charAt(0) != "_") {
        this.availableNames[
          this.availableNames.findIndex(name => name.label === chip.label)
        ].value = false;
        this.selected.splice(
          this.selected.findIndex(name => name.label === chip.label),
          1
        );
      } else {
        this.selected.splice(
          this.selected.findIndex(name => name.id === chip.id),
          1
        );
      }

      this.createNameString();
    },
    next() {
      this.$emit("nameSelected", this.name);
      this.setCartInfo({ stepThree: this.nameForm });
    },
    previous() {
      this.$emit("previous");
    },
    containsName(name) {
      if (this.selected.some(e => e.label === name)) {
        return true;
      } else return false;
    }
  },
  mounted() {
    if (this.cart.cartInfos.stepThree) {
      this.createNameString();
    }
  },
  watch: {
    trigerName() {
      this.createNameString();
    }
  },
  created() {
    if (this.cart.cartInfos.stepThree) {
      this.selected = [];
      this.recreate(this.cart.cartInfos.stepThree.split("/"));
      this.$emit("nameSelected", this.name);
    } else this.createDefault();
  }
};
</script>
<style scoped lang="scss">
.chip-container {
  border-top: 1px solid $primary-l1;
  border-bottom: 1px solid $primary-l1;
  min-height: 60px;
}
</style>
