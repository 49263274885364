<template>
  <v-container px-3>
    <p>
      {{ $t("images.firstStep.selectGtinFormat") }}
    </p>
    <div>
      <v-radio-group v-model="barcode14" row>
        <v-radio :label="$t('common.barcode8_13')" :value="false"></v-radio>
        <v-radio :label="$t('common.barcode14')" :value="true"></v-radio>
      </v-radio-group>
    </div>
    <v-divider></v-divider>
    <p class="mt-5">
      {{ $t("images.firstStep.subTitle") }}
      <!-- <span @click="openDialog" class="pointer link--text">
        {{ $t("common.customize") }}</span
      > -->
    </p>
    <v-checkbox
      v-model="images"
      :label="$t('common.images')"
      @click="imagesSelected()"
      class="custom-checkbox"
      :disabled="skipImages"
    ></v-checkbox>
    <div class="d-flex flex-row flex-wrap">
      <v-radio-group
        v-for="(format, index) in formats"
        :key="index"
        v-model="selected"
        :disabled="!images"
      >
        <span :class="{ active_label: images }" class="mb-4">{{
          format.title
        }}</span>
        <v-radio
          v-for="value in format.values"
          :key="value.key"
          :label="value.label"
          :value="value.value"
        ></v-radio>
        <!-- <div v-if="format.title === 'tue personalizzazioni'">
          <v-btn
            small
            color="primary"
            text
            class="text-capitalize px-1"
            @click="openDialog"
          >
            <v-icon left> mdi-pencil-outline </v-icon
            >{{ $t("images.firstStep.createNewType") }}</v-btn
          >
        </div> -->
      </v-radio-group>
    </div>
    <v-divider></v-divider>
    <v-checkbox
      v-model="file"
      :label="$t('images.firstStep.file')"
      @click="fileSelected()"
      class="custom-checkbox"
    ></v-checkbox>
    <div class="d-flex flex-row flex-wrap pt-3">
      <div class="w-25 custom-radio-group">
        <span :class="{ active_label: file }" class=" mb-4 pt-3">{{
          $t("images.firstStep.formatSheetData")
        }}</span>
        <v-radio-group v-model="fileTypeSelected.dataSheetFormatType">
          <v-radio
            v-for="(type, index) in fyleTypes"
            :key="index"
            :label="type.label"
            :value="type.value"
            :disabled="!file"
          ></v-radio>
        </v-radio-group>
      </div>
      <div
        v-if="fileTypeSelected.dataSheetFormatType === 'PDF'"
        class="w-25 custom-radio-group"
      >
        <span class="primary--text mb-4 pt-3">{{
          $t("images.firstStep.PdfByProduct")
        }}</span>
        <v-radio-group v-model="fileTypeSelected.isPdfSinglePageRequest">
          <v-radio
            v-for="(option, index) in pdfOptions"
            :key="index"
            :label="option.label"
            :value="option.value"
          ></v-radio>
        </v-radio-group>
      </div>
    </div>
    <div class="d-flex justify-end pt-6">
      <v-btn color="primary" @click="next" :disabled="buttonDisabled">
        {{
          images ? $t("images.secondStep.title") : $t("images.fourthStep.title")
        }}
        <v-icon right>
          mdi-arrow-right
        </v-icon>
      </v-btn>
    </div>
    <v-dialog
      v-model="dialog"
      persistent
      :fullscreen="$vuetify.breakpoint.xsOnly"
      ><PersonalizeImage
        v-if="product"
        @closeDialog="closeDialog"
        @save="addFormat"
        :imageUrl="product.media[0].large"
    /></v-dialog>
  </v-container>
</template>

<script>
//import CartService from "~/service/cartService";
import { mapActions } from "vuex";
import PersonalizeImage from "./PersonalizeImage.vue";

export default {
  name: "ImageFormat",
  props: {
    product: { required: true, type: Object },
    cart: { type: Object },
    skipImages: { required: true, type: Boolean }
  },
  components: {
    PersonalizeImage
  },
  data() {
    return {
      dialog: false,
      selected: false,
      images: false,
      file: false,
      barcode14: false,
      fileTypeSelected: {
        dataSheetFormatType: false,
        isPdfSinglePageRequest: null
      },
      formats: [
        {
          title: "Originale transparente",
          values: [
            {
              label: "2.400 x 2.400",
              value: { resolution: "2400x2400", dpi: "300" }
            }
          ]
        },
        {
          title: "per la stampa 300 DPI",
          values: [
            {
              label: "1.500 x 1.500",
              key: 1,
              value: { resolution: "1500x1500", dpi: "300" }
            },
            {
              label: "800 x 800",
              key: 2,
              value: { resolution: "800x800", dpi: "300" }
            },
            {
              label: "500 x 500",
              key: 3,
              value: { resolution: "500x500", dpi: "300" }
            }
          ]
        },
        {
          title: "per il web 72 DPI",
          values: [
            {
              label: "1.000 x 1.000",
              key: 4,
              value: { resolution: "1000x1000", dpi: "72" }
            },
            {
              label: "900 x 900",
              key: 5,
              value: { resolution: "900x900", dpi: "72" }
            },
            {
              label: "800 x 800",
              key: 6,
              value: { resolution: "800x800", dpi: "72" }
            },
            {
              label: "600 x 600",
              key: 7,
              value: { resolution: "600x600", dpi: "72" }
            },
            {
              label: "500 x 500",
              key: 8,
              value: { resolution: "500x500", dpi: "72" }
            },
            {
              label: "400 x 400",
              key: 9,
              value: { resolution: "400x400", dpi: "72" }
            },
            {
              label: "300 x 300",
              key: 10,
              value: { resolution: "300x300", dpi: "72" }
            },
            {
              label: "200 x 200",
              key: 11,
              value: { resolution: "200x200", dpi: "72" }
            }
          ]
        }
        // {
        //   title: "tue personalizzazioni",
        //   values: [
        //     {
        //       label: "imagini per sito",
        //       key: 12,
        //       value: { resolution: "1500x1500", dpi: "300" }
        //     },
        //     {
        //       label: "imagini per mobile",
        //       key: 13,
        //       value: { resolution: "200x200", dpi: "72" }
        //     }
        //   ]
        // }
      ],
      fyleTypes: [
        {
          label: "XLSX",
          value: "XLSX"
        },
        {
          label: "CSV",
          value: "CSV"
        },
        {
          label: "PDF",
          value: "PDF"
        },
        {
          label: "JSON",
          value: "JSON"
        },
        {
          label: "GDSN XLSX",
          value: "GDSN_XLSX"
        }
      ],
      pdfOptions: [
        { label: "un pdf per prodotto", value: false },
        { label: "prodotti aggregati su unico pdf", value: true }
      ]
    };
  },
  computed: {
    buttonDisabled() {
      if (
        (this.images && !this.imagSelected) ||
        (this.file && !this.fSelected) ||
        (!this.images && !this.file)
      ) {
        return true;
      }
      return false;
    },
    imagSelected() {
      if (!this.selected === false) {
        return true;
      }
      return false;
    },
    fSelected() {
      if (
        this.fileTypeSelected.dataSheetFormatType === false ||
        (this.fileTypeSelected.dataSheetFormatType === "PDF" &&
          (this.fileTypeSelected.isPdfSinglePageRequest === undefined ||
            this.fileTypeSelected.isPdfSinglePageRequest === null))
      ) {
        return false;
      }
      return true;
    }
  },
  methods: {
    ...mapActions({
      setCartInfo: "cart/setCartInfo"
    }),
    next() {
      this.$emit(
        "formatSelected",
        { images: this.selected, file: this.fileTypeSelected },
        this.selected ? true : false
      );
      // this.setCartInfo({
      //   stepOne: JSON.stringify(this.selected)
      // });
      if (this.fileTypeSelected.dataSheetFormatType !== "PDF") {
        delete this.fileTypeSelected.isPdfSinglePageRequest;
      }
      this.setCartInfo({
        stepOne: JSON.stringify({
          barcode14: this.barcode14,
          images: this.selected,
          file: this.fileTypeSelected
        })
      });
    },
    openDialog() {
      this.dialog = true;
    },
    addFormat(format) {
      this.formats[3].values = this.formats[3].values.filter(
        item => item.label !== "pesonalizza"
      );
      console.log("newFormat", format);
      this.formats[3].values.push({
        label: "pesonalizza",
        key: 14,
        value: format
      });
      this.selected = format;
      console.log("this.selected", this.selected);
      this.closeDialog();
    },
    closeDialog() {
      this.dialog = false;
    },
    imagesSelected() {
      if (!this.images) {
        this.selected = false;
      }
    },
    fileSelected() {
      if (!this.file) {
        this.fileTypeSelected = {
          dataSheetFormatType: false,
          isPdfSinglePageRequest: null
        };
      }
    }
  },
  created() {
    if (this.cart.cartInfos.stepOne) {
      const stepOne = JSON.parse(this.cart.cartInfos.stepOne);
      let images = stepOne.images;
      let file = stepOne.file;
      this.barcode14 = stepOne.barcode14;
      this.formats.forEach(format =>
        format.values.forEach(f => {
          if (
            f.value &&
            images != false &&
            f.value.resolution == images.resolution &&
            f.value.dpi == images.dpi
          ) {
            this.selected = f.value;
            this.images = true;
          }
        })
      );
      if (file && file.dataSheetFormatType != false) {
        this.fileTypeSelected = file;
        this.file = true;
      }

      this.$emit(
        "formatSelected",
        { images: this.selected, file: this.fileTypeSelected },
        this.selected ? true : false
      );
    }
  }
};
</script>
<style scoped lang="scss">
.pointer {
  cursor: pointer;
}
.custom-radio-group {
  min-width: 180px;
  margin-right: 5px;
}
.active_label {
  color: $primary;
}
.v-input--radio-group--column {
  min-width: 25%;
}
@media (max-width: 990px) {
  .v-input--radio-group--column {
    min-width: 50%;
  }
}
@media (max-width: 500px) {
  .v-input--radio-group--column {
    min-width: 100%;
  }
}
</style>
