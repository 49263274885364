<template>
  <v-container>
    <p>{{ $t("images.secondStep.subTitle") }}</p>
    <h3 v-if="imageOptions.format && imageOptions.format.images">
      {{ imageOptions.format.images.resolution }}
      {{ imageOptions.format.images.dpi }} DPI
    </h3>
    <div>
      <v-checkbox
        v-model="selected"
        v-for="(type, index) in shotTypes"
        :key="index"
        :label="type.label"
        :value="type.value"
        dense
        hide-details
      ></v-checkbox>
    </div>
    <div class="d-flex justify-end pt-6">
      <v-btn color="primary" outlined @click="previous" class="mr-3">
        <v-icon left>
          mdi-arrow-left
        </v-icon>
        {{ $t("images.firstStep.title") }}
      </v-btn>
      <v-btn color="primary" @click="next" :disabled="selected.length < 1">
        {{ $t("images.thirdStep.title") }}
        <v-icon right>
          mdi-arrow-right
        </v-icon>
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "TypeOfShot",
  props: {
    imageOptions: { required: true, type: Object },
    cart: { type: Object },
    availableTypesForUser: { required: true, type: Array }
  },
  computed: {
    shotTypes() {
      return this.availableTypes.filter(e => {
        return this.availableTypesForUser.includes("T" + e.value);
      });
    }
  },
  data() {
    return {
      selected: [],
      availableTypes: [
        { label: "T1 - ID shot", value: "1" },
        { label: "T5 - Angolato - Parte anteriore, superiore", value: "5" },
        {
          label: "T8 - Angolato - Parte anteriore, destra, superiore",
          value: "8"
        },
        { label: "T70 - Parte anteriore planogramma", value: "70" },
        { label: "T71 - Parte sinistra planogramma", value: "71" },
        { label: "T72 - Parte posteriore planogramma", value: "72" },
        { label: "T73 - Parte destra planogramma", value: "73" },
        { label: "T74 - Parte superiore planogramma", value: "74" },
        { label: "T75 - Parte inferiore planogramma", value: "75" }
      ]
    };
  },
  methods: {
    ...mapActions({
      setCartInfo: "cart/setCartInfo"
    }),
    next() {
      this.$emit("typeSelected", this.selected);
      this.setCartInfo({ stepTwo: "T".concat(this.selected.join(",T")) });
    },
    previous() {
      this.$emit("previous");
    }
  },
  async created() {
    if (this.cart.cartInfos.stepTwo) {
      this.selected = this.cart.cartInfos.stepTwo.replace(/T/g, "").split(",");
      this.$emit("typeSelected", this.selected);
    }
  }
};
</script>
