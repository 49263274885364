<template>
  <v-container>
    <!-- <p>{{ $t("images.fourthStep.subTitle") }}</p> -->
    <div class="pt-10">
      <p>{{ $t("images.fourthStep.enterEmail") }}</p>
      <v-row>
        <v-col cols="12" sm="8" md="4">
          <v-form ref="form" v-model="isFormValid" v-on:submit.prevent>
            <v-text-field
              v-model="mail"
              label="Email"
              solo
              flat
              outlined
              dense
              elevation="0"
              :rules="rules"
              v-on:keyup.enter="addEmail"
              v-on:blur="handleBlur"
            ></v-text-field>
          </v-form>
        </v-col>
        <v-col cols="12" sm="4" md="2">
          <v-btn
            color="primary"
            :loading="loading"
            :disabled="!isFormValid"
            @click="addEmail"
          >
            {{ $t("images.fourthStep.addEmail") }}
          </v-btn>
        </v-col>
      </v-row>
      <div class="mail-chips">
        <v-chip
          v-for="(mail, index) in emails"
          :key="index"
          close
          close-icon="mdi-close"
          color="secondary"
          label
          class="ma-2"
          @click:close="removeEmail(index)"
        >
          <v-icon left>
            mdi-email-outline
          </v-icon>
          {{ mail }}
        </v-chip>
      </div>
    </div>
    <div class="d-flex justify-end pt-6">
      <v-btn
        color="primary"
        outlined
        @click="previous"
        class="text-capitalize mr-3"
      >
        <v-icon left> mdi-arrow-left </v-icon>
        {{
          hasImages
            ? $t("images.thirdStep.title")
            : $t("images.firstStep.title")
        }}
      </v-btn>

      <v-btn
        color="primary"
        @click="goToPayment"
        :loading="loading"
        :disabled="submitDisabled"
      >
        {{ $t("common.confirm") }}
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import UserService from "~/service/userService";

export default {
  name: "NameFile",
  props: {
    imageOptions: { required: true, type: Object },
    cart: { type: Object }
  },
  data() {
    return {
      mail: null,
      isFormValid: false,
      billingData: null,
      emails: [],
      rules: [
        //value => !!value || this.$t("images.fourthStep.required"),
        value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return (
            pattern.test(value) || this.$t("images.fourthStep.invalidEmail")
          );
        }
        // value =>
        //   this.emailExists(value) || this.$t("images.fourthStep.alreadyExists")
      ],
      loading: false,
      error: null,
      response: {}
    };
  },
  computed: {
    hasImages() {
      if (
        this.imageOptions &&
        this.imageOptions.format &&
        this.imageOptions.format.images
      ) {
        return true;
      } else return false;
    },
    submitDisabled() {
      return !this.isFormValid && this.emails.length < 1;
    }
  },
  methods: {
    ...mapActions({
      setCartInfo: "cart/setCartInfo",
      lockCart: "cart/lockCart",
      buyCart: "cart/buyCart"
    }),
    next() {
      this.$emit("emailSelected", this.mail);
      this.setCartInfo({ stepFour: this.mail });
    },
    previous() {
      this.hasImages ? this.$emit("previous") : this.$emit("toStart");
    },
    async goToPayment() {
      if (this.emails.length == 0 && this.isFormValid) {
        this.setCartInfo({ stepFour: this.mail });
      } else if (this.emails.length > 0) {
        this.setCartInfo({ stepFour: this.emails.join(",") });
      }
      let vm = this;
      vm.loading = true;
      try {
        await vm.lockCart();
        vm.buy();
      } catch (err) {
        vm.error = err.message;
      } finally {
        vm.loading = false;
      }
    },
    async buy() {
      // UPDATE CART INFO AND PAY
      //var _this = this;
      let vm = this;
      vm.loading = true;
      try {
        let cart = await vm.buyCart({
          paymentTypeId: 2
        });
        if (cart) {
          let orderId = cart.order ? cart.order.orderId : cart.orderId;
          global.EventBus.$emit("pay-response-ok", cart);
          this.$router.push({
            name: "PaymentCompleted",
            params: { orderId: orderId }
          });
        } else {
          vm.$router.push("/payment-error");
        }
      } catch (err) {
        vm.response = err;
      } finally {
        vm.loading = false;
      }
    },
    addEmail() {
      if (this.isFormValid) {
        this.emails.push(this.mail);
        this.mail = "";
        this.$refs.form.resetValidation();
      }
    },
    removeEmail(index) {
      console.log(index);
      this.emails.splice(index, 1);
    },
    handleBlur() {
      if (this.mail == "") {
        this.$refs.form.resetValidation();
      }
    },
    emailExists(mail) {
      if (this.emails.includes(mail)) return false;
      else return true;
    }
  },
  async mounted() {
    let that = this;
    try {
      let res = await UserService.getUserDetail();
      if (res && !res.errors) {
        this.billingData = res;
      } else {
        if (res.errors) {
          that.error = true;
        }
      }
    } catch (response) {
      this.response = response;
    } finally {
      this.mail = this.billingData.email;
    }
  }
};
</script>
